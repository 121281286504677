<template>
  <div class="saa-products-container">
    <account-role-title role="Admin" />
    <!-- <breadcrumbs /> -->
    <span class="title">{{ $t("Payouts") }}</span>
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows class="deals-tabs">
      <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="$router.push({name:'admin-payouts', params:{tab:tab.route}, query:{t:new Date().getTime()}})"
      >
        <span>{{ $t(tab.title) }}</span>
      </v-tab>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index">
          <!-- tab component -->
          <component v-if="tab.route === $route.params.tab" :is="tab.component" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import {useRouter} from "@core/utils";
import {ref, watch} from "@vue/composition-api";
import PendingPayouts from "./PendingPayouts";
import PaidPayouts from "./PaidPayouts";

export default {
  name: "Payouts",
  setup() {
    const tabs = [
      { title: 'Pending', route: 'pending', component: PendingPayouts },
      { title: 'Paid', route: 'paid', component: PaidPayouts },
    ]
    const { route, router } = useRouter()
    const tab = ref(tabs.findIndex(tab => tab.route === route.value.params.tab))
    watch(() => route.value.params.tab, (newTab) => {
          tab.value = tabs.findIndex(tab => tab.route === newTab)
        }
    )
    return {
      tab,
      tabs,
    }
  }
}
</script>
