var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payoutList,"options":_vm.options,"server-items-length":_vm.totalPayouts,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': _vm.loading,
      'disable-items-per-page': _vm.loading,
    },"mobile-breakpoint":100,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getPayoutsFromApi,"update:sort-desc":_vm.getPayoutsFromApi},scopedSlots:_vm._u([{key:"item.store_title",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.user_id, tab: 'store'}}}},[_vm._v(_vm._s(item.store_title))])]}},{key:"item.store_id",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.payout_method.data?item.payout_method.data.email:'-'))])]}},{key:"item.payout_method",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.payout_method.type||'-'))])]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(item.amount))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }