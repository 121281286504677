<template>
  <v-data-table
      :headers="headers"
      :items="payoutList"
      :options.sync="options"
      :server-items-length="totalPayouts"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      :mobile-breakpoint="100"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getPayoutsFromApi"
      @update:sort-desc="getPayoutsFromApi"
      :items-per-page="globalItemsPerPage"
  >
  <template #[`item.store_title`]="{item}">
    <router-link :to="{name: 'user', params: {id: item.user_id, tab: 'store'}}">{{item.store_title}}</router-link>
  </template>
  <template #[`item.store_id`]="{item}">
    <span>{{item.payout_method.data?item.payout_method.data.email:'-'}}</span>
  </template>
  <template #[`item.payout_method`]="{item}">
    <span>{{item.payout_method.type||'-'}}</span>
  </template>
  <template #[`item.amount`]="{item}">
    <span>${{item.amount}}</span>
  </template>
  </v-data-table>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import PayoutDialog from "@/views/cabinet/payouts/payoutDialog";
export default {
  setup() {
    const options = computed(({
      get() {
        return store.state.pagination.payouts.paid
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob.paid = Object.assign({}, val, {
          page
        })
        store.commit("pagination/SET_PAYOUTS_PAGINATION", ob);
      }
    }))
    const getPayoutsFromApi = () => {
      store.dispatch('payouts/getPaidPayouts', options.value)
    }
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getPayoutsFromApi()
    }
    getPayoutsFromApi()
    return {
      loading: computed(() => store.state.payouts.paidLoading),
      payoutList: computed(() => store.state.payouts.paidPayouts),
      globalItemsPerPage: computed(() => store.getters['nav/globalItemPerPage']),
      getPayoutsFromApi,
      updateItemsPerPage,
      options,
      totalPayouts: computed(() => store.state.payouts.totalPaid),
      headers: [
        {text: 'ID', value: 'id', sortable: false, width: 350},
        {text: 'STORE', value: 'store_title', sortable: false},
        {text: 'PAYOUT METHOD', value: 'payout_method', sortable: false},
        {text: 'PREVIEW', value: 'store_id', sortable: false},
        {text: 'TOTAL', value: 'amount', sortable: false},
        {text: 'DATE', value: 'created_at'}
      ]
    }
  }
}
</script>