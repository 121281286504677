<template>
  <v-data-table
      :headers="headers"
      :items="payoutList"
      :options.sync="options"
      :server-items-length="totalPayouts"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      :mobile-breakpoint="100"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getPayoutsFromApi"
      @update:sort-by="getPayoutsFromApi"
      :items-per-page="globalItemsPerPage"
  >
  <template #[`item.store_title`]="{item}">
    <router-link :to="{name: 'user', params: {id: item.user_id, tab: 'store'}}">{{item.store_title}}</router-link>
  </template>
  <template #[`item.store_id`]="{item}">
    <span>{{item.payout_method.data?item.payout_method.data.email:'-'}}</span>
  </template>
  <template #[`item.payout_method`]="{item}">
    <span>{{item.payout_method.type||'-'}}</span>
    <v-tooltip
      v-if="item.payout_method.messages && item.payout_method.messages.length"
      bottom
      color="white"
      content-class="payout-tooltip"
      transition="scale-transition"
      open-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          color="error"
          class="pl-4"
        >
          {{ icons.mdiAlertOctagonOutline }}
        </v-icon>
      </template>
      <div
        v-for="(message, index) in item.payout_method.messages"
        :key="index"
      >
        <span class="text-black"><b>{{ $t('Payout method problem') + ((item.payout_method.messages.length - 1) > 0 ? ' ' + (index + 1) : '') }}:</b> {{ message }}</span></br>
      </div>
    </v-tooltip>
  </template>
  <template #[`item.amount`]="{item}">
    <span>${{item.amount}}</span>
  </template>
  <template #[`item.user_id`]="{item}">
    <payout-dialog
        :payout-method="item.payout_method.type"
        :payout-method-invalid="isInvalid(item.payout_method.messages)"
        :data="item.payout_method.data"
        :payout-id="item.payout_method.id"
        :store-id="item.store_id"
        :amount="item.amount"
        :is-payable="item.is_payable"/>
  </template>
  </v-data-table>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import PayoutDialog from "@/views/cabinet/payouts/payoutDialog";
import {mdiAlertOctagonOutline} from "@mdi/js";

export default {
  components: {
    PayoutDialog
  },
  setup() {
    const options = computed(({
      get() {
        return store.state.pagination.payouts.pending
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        ob.pending = Object.assign({}, val, {
          page
        })
        store.commit("pagination/SET_PAYOUTS_PAGINATION", ob);
      }
    }))
    const getPayoutsFromApi = () => {
      store.dispatch('payouts/getPendingPayouts', options.value)
    }
    getPayoutsFromApi()
    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getPayoutsFromApi()
    }
    const isInvalid = (messages) => {
      return messages ? !!messages.length : false
    }
    return {
      isInvalid,
      loading: computed(() => store.state.payouts.pendingLoading),
      payoutList: computed(() => store.state.payouts.pendingPayouts),
      globalItemsPerPage: computed(() => store.getters['nav/globalItemPerPage']),
      getPayoutsFromApi,
      updateItemsPerPage,
      options,
      totalPayouts: computed(() => store.state.payouts.totalPending),
      headers: [
        {text: 'STORE', value: 'store_title', sortable: false},
        {text: 'PAYOUT METHOD', value: 'payout_method', sortable: false},
        {text: 'PREVIEW', value: 'store_id', sortable: false},
        {text: 'TOTAL', value: 'amount', sortable: false},
        {text: '', value: 'user_id', sortable: false}
      ],
      icons: {
        mdiAlertOctagonOutline
      }
    }
  }
}
</script>