var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payoutList,"options":_vm.options,"server-items-length":_vm.totalPayouts,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
      'disable-pagination': _vm.loading,
      'disable-items-per-page': _vm.loading,
    },"mobile-breakpoint":100,"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getPayoutsFromApi,"update:sort-by":_vm.getPayoutsFromApi},scopedSlots:_vm._u([{key:"item.store_title",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'user', params: {id: item.user_id, tab: 'store'}}}},[_vm._v(_vm._s(item.store_title))])]}},{key:"item.store_id",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.payout_method.data?item.payout_method.data.email:'-'))])]}},{key:"item.payout_method",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.payout_method.type||'-'))]),(item.payout_method.messages && item.payout_method.messages.length)?_c('v-tooltip',{attrs:{"bottom":"","color":"white","content-class":"payout-tooltip","transition":"scale-transition","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pl-4",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOctagonOutline)+" ")])]}}],null,true)},_vm._l((item.payout_method.messages),function(message,index){return _c('div',{key:index},[_c('span',{staticClass:"text-black"},[_c('b',[_vm._v(_vm._s(_vm.$t('Payout method problem') + ((item.payout_method.messages.length - 1) > 0 ? ' ' + (index + 1) : ''))+":")]),_vm._v(" "+_vm._s(message))]),_c('br')])}),0):_vm._e()]}},{key:"item.amount",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v("$"+_vm._s(item.amount))])]}},{key:"item.user_id",fn:function(ref){
    var item = ref.item;
return [_c('payout-dialog',{attrs:{"payout-method":item.payout_method.type,"payout-method-invalid":_vm.isInvalid(item.payout_method.messages),"data":item.payout_method.data,"payout-id":item.payout_method.id,"store-id":item.store_id,"amount":item.amount,"is-payable":item.is_payable}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }