<template>
  <v-dialog
      v-model="isVisible"
      width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="isPayable"
          v-bind="attrs"
          v-on="on"
          color="success"
          rounded
          small
          :disabled="!payoutMethod || payoutMethodInvalid"
      >
        {{ $t('Pay') }}
      </v-btn>
      <v-btn
          v-else
          v-bind="attrs"
          v-on="on"
          color="success"
          outlined
          rounded
          small
          :disabled="!payoutMethod || payoutMethodInvalid"
      >
        {{ $t('Pay') }}
      </v-btn>
    </template>
    <v-card :loading="loading" class="payout-dialog">
      <v-card-title class="justify-center">
        {{ $t('Pay one payout') }}
      </v-card-title>
      <v-card-title class="justify-center">
        <b>{{ payoutMethod }}</b>
      </v-card-title>
      <v-list class="mb-5">
        <v-list-item v-for="(val, key) in data" :key="key" class="justify-space-between payout-item">
          <span class="col text--secondary" md="auto">{{ key.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`) }}:</span>
          <span class="pr-2 text--secondary">{{ val }}
            <v-icon 
              class="cursor-pointer mb-1"
              small
              color="primary"
              @click="copyText(val)"
            >
              {{ mdiClipboardMultipleOutline }}
            </v-icon>
          </span>
        </v-list-item>
        <v-list-item class="justify-space-between payout-item">
          <span class="col text--secondary" md="auto">{{ $t('total') }}
            <v-icon 
              class="cursor-pointer mb-1"
              small
              color="primary"
              @click="openInput()"
            >
              {{ mdiSquareEditOutline }}
            </v-icon>
          </span>
          <span class="pr-2 text--secondary" v-if="!inputVisible">${{ total }}
            <v-icon 
              class="cursor-pointer mb-1"
              small
              color="primary"
              @click="copyText(total)"
            >
              {{ mdiClipboardMultipleOutline }}
            </v-icon>
          </span>
          <span v-else>
            <v-text-field v-model="total" class="ma-0 pa-0 center" hide-details />
          </span>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="markInvalidDialog"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="error"
              outlined
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('Mark Invalid') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ $t('Payment method problem') }}
            </v-card-title>
            <v-card-text class="pb-0">
              <v-textarea
                outlined
                :label="$t('Description')"
                v-model="markInvalidDescription"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="disabled"
                text
                @click="markInvalidDialog = false"
              >
                {{ $t('Close') }}
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="markInvalid()"
              >
                {{ $t('Send') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
            color="secondary"
            outlined
            @click="isVisible = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
            :color="buttonColor()"
            @click="pay()"
            :disabled="loading"
        >
          {{ $t(buttonText()) }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
          v-model="clipboardSnackbar"
          timeout="2000"
      >
        {{ $t('Copied to clipboard') }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="clipboardSnackbar = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, getCurrentInstance, ref, watch} from "@vue/composition-api";
import {mdiClipboardMultipleOutline} from "@mdi/js";
import {mdiSquareEditOutline} from "@mdi/js";
import {mdiCheck} from "@mdi/js";
import {mdiClose} from '@mdi/js';
import axios from "@axios";
import store from "@/store";

export default {
  name: "payoutDialog",
  props: {
    payoutMethod: String,
    payoutMethodInvalid: Boolean,
    payoutId: Number,
    data: Object,
    storeId: Number,
    amount: Number,
    isPayable: Boolean
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const markInvalidDialog = ref(false)
    const markInvalidDescription = ref('')
    const inputVisible = ref(false)
    const total = ref(props.amount.toFixed(2))
    const isVisible = ref(false)
    const loading = ref(false)
    const clipboardSnackbar = ref(false)
    const options = computed(() => store.state.pagination.payouts.pending)
    const copyText = text => {
      navigator.clipboard.writeText(text);
      clipboardSnackbar.value = true
    }
    watch(isVisible, () => {
      if(!isVisible.value) inputVisible.value = false
      total.value = isVisible.value ? props.amount.toFixed(2) : null
    })
    const buttonColor = () => total.value >= 0 ? 'success' : 'warning'
    const buttonText = () => total.value >= 0 ? 'Pay' : 'Revert'
    const openInput = () => inputVisible.value = !inputVisible.value
    const pay = () => {
      if (Number(total.value) == total.value) {
        loading.value = true
        axios.post('admin/payout', {
          pm_id: props.payoutId,
          market_id: props.storeId,
          amount: total.value
        }).then(res => {
          vm.$notify({
            group: 'deals',
            title: vm.$t('Payout'),
            text: vm.$t('payout done'),
            type: 'success',
          })
          store.dispatch('payouts/getPendingPayouts', options.value)
          isVisible.value = false
          loading.value = false
        }).catch(er => {
          vm.$notify({
            group: 'deals',
            title: vm.$t('Payout'),
            text: vm.$t('something went wrong'),
            type: 'error',
          })
          loading.value = false
        })
      } else {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Payout'),
          text: vm.$t('total is invalid'),
          type: 'error',
        })
      }
    }
    const markInvalid = () => {
      loading.value = true
      axios.post('admin/payout-method-invalid', {
        pm_id: props.payoutId,
        market_id: props.storeId,
        description: markInvalidDescription.value
      }).then(res => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Payout method invalid'),
          text: vm.$t('message sended'),
          type: 'success',
        })
        store.dispatch('payouts/getPendingPayouts', options.value)
        markInvalidDialog.value = false
        isVisible.value = false
        loading.value = false
      }).catch(er => {
        vm.$notify({
          group: 'deals',
          title: vm.$t('Payout method not updated'),
          text: vm.$t('something went wrong'),
          type: 'error',
        })
        loading.value = false
      })
    }
    return {
      buttonColor,
      buttonText,
      markInvalid,
      markInvalidDialog,
      markInvalidDescription,
      total,
      isVisible,
      loading,
      inputVisible,
      copyText,
      openInput,
      pay,
      clipboardSnackbar,
      mdiClipboardMultipleOutline,
      mdiSquareEditOutline,
      mdiCheck,
      mdiClose
    }
  }
}
</script>
